<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo />
        <!-- End Header Area -->
        <!-- Start Slider Area  -->
      <div class="rn-section-gapDoubleTop bg_color--1">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="section-title text-center mb--20 mb_sm--0">
                <h2 class="heading-title" align="center">Schedule</h2>
                <div class="about-area">
                  <div class="about-wrapper">
                    <ScheduleDetails />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <Footer />
    </div>
</template>

<script>
  import HeaderOnePageTwo from "../../components/header/HeaderOnePageTwo";
  import BannerThree from "../../components/slider/BannerThree";
  import InvitationDetails from "../../components/invitation/InvitationDetails";
  import ScheduleDetails from "../../components/schedule/ScheduleDetails";
  import ServiceThree from "../../components/service/ServiceThree";
  import PortfolioTwo from "../../components/portfolio/PortfolioFour";
  import Blog from "../../components/blog/Blog";
  import Contact from "../../components/contact/Contact";
  import Footer from "../../components/footer/FooterTwo";

  export default {
  components: {
  HeaderOnePageTwo,
  BannerThree,
  InvitationDetails,
  ServiceThree,
  ScheduleDetails,
  PortfolioTwo,
  Blog,
  Contact,
  Footer,
  },
  data() {
  return {};
  },
  };
</script>

<style lang="scss">
  .feather-menu {
    color: #1d1d24;
  }
</style>
