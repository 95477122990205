<template>

  <v-container>
    <br />
    <br />
    <v-row v-for="(item, i) in schedule"
             :key="i">
      <v-col lg="5" md="5" cols="12">
        <div class="about-inner inner">
          <div class="section-title super-right" >
            <p class=" super-right time-format">{{item.time}} </p>
          </div>
        </div>
      </v-col>
      <v-col lg="7" md="7" cols="12">
        <div class="about-inner inner">
          <div class="section-title schedule-details">
            <p class="schedule-details">{{item.details}}</p>
          </div>
        </div>
        <br />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12" md="12" cols="12">
        <div class="about-inner inner">
          <div class="super-center">
            <p class="super-center">Please note, food will be served cocktail style throughout the evening</p>
          </div>
        </div>
        <br />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
  data() {
  return {
  schedule: [
  {time: "3:30 pm", details: "gates open for arrival"},
  {time: "4:00 pm", details: "ceremony commences"},
  {time: "4:30 pm", details: "ceremony concludes + group and family photos"},
  {time: "5:00 pm", details: "cocktail hour"},
  {time: "5:30 pm", details: "bridal party entrance + kids meals served"},
  {time: "6:00 pm", details: "speeches + cake cutting"},
  {time: "8:00 pm", details: "dessert service begins"},
  {time: "8:30 pm", details: "spirit service commences"},
  {time: "8:45 pm", details: "first dance, band starts + dance floor opens"},
  {time: "9:30 pm", details: "late night snack served"},
  {time: "10:45 pm", details: "bar service concludes"},
  {time: "11:00 pm", details: "reception concludes + bus transport arrives"},
  {time: "11:30 pm", details: "guests vacated + gates closed"},
  ],
  };
  },
  };

</script>


<style lang="scss">
  $large-mobile: 'only screen and (max-width: 599px)';
  .super-center {
  text-align: center;
  }

  .schedule-details {
  text-align: left;
  padding-right:0% !important;
  padding-left:0% !important;
  @media #{$large-mobile} {
  text-align: center;
  }
  }

  .time-format{
  @media #{$large-mobile} {
    font-weight: bold;
  }
  }

  .super-right {
  text-align: right;
  padding-right:0% !important;
  padding-left:0% !important;
  @media #{$large-mobile} {
  text-align: center;
  }
  }

  .removeDisplay {
  display: normal !important;
  }

  .img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  }

  .line-border {
  border-bottom: solid;
  }

  .time-displays {
  font-size: 20px;
  }
</style>
